<template>
  <div ref="container" class="w-full"></div>
</template>

<script>
import places from 'places.js';
import { setLocation, useLocation } from '@/store/shoppingCart';

const PLACES_OPTIONS = {
  language: 'es', // Receives results in Spanish
  countries: ['mx'], // Search in Mexico
  type: 'city', // Search only for cities names
  aroundLatLngViaIP: true, // disable the extra search/boost around the source IP
};
/*
const CHANGE_CALLBACK = function(e) {
  this.$emit("change", e);
}; */

export default {
  emits: ['change'],
  data: () => ({
    placesAutocomplete: null,
  }),
  mounted() {
    const location = useLocation();
    // make sure Vue does not know about the input
    // this way it can properly unmount
    this.input = document.createElement('input');
    this.input.id = 'ubicacion';
    this.$refs.container.appendChild(this.input);

    this.placesAutocomplete = places({
      appId: process.env.VUE_APP_ALGOLIA_PLACES_APP_ID,
      apiKey: process.env.VUE_APP_ALGOLIA_PLACES_SEARCH_KEY,
      container: this.input,
    }).configure(PLACES_OPTIONS);

    if (location.value !== null) {
      this.placesAutocomplete.setVal(location.value?.value);
    } else {
      setLocation({
        value: 'Monterrey, Nuevo León, México',
        administrative: 'Nuevo León',
        country: 'México',
        name: 'Monterrey',
        type: 'city',
        latlng: {
          lat: 25.6802,
          lng: -100.315,
        },
      });
      this.placesAutocomplete.setVal('Monterrey, Nuevo León, México');
    }

    this.placesAutocomplete.on('change', this.changeCallback);
  },
  beforeUnmount() {
    this.placesAutocomplete.off('change', this.changeCallback);
    this.placesAutocomplete.destroy();
  },
  methods: {
    changeCallback(algoliaResponse) {
      setLocation(algoliaResponse.suggestion);
      this.$emit('change');
    },
  },
};
</script>

<style>
.ap-footer {
  display: none;
}
</style>
